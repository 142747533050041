export enum Country {
  Albania = "al",
  Algeria = "dz",
  Argentina = "ar",
  Armenia = "am",
  Australia = "au",
  Austria = "at",
  Azerbaijan = "az",
  Bahrain = "bh",
  Belgium = "be",
  Bolivia = "bo",
  Brazil = "br",
  Bulgaria = "bg",
  Cambodia = "kh",
  Canada = "ca",
  Chile = "cl",
  China = "cn",
  Colombia = "co",
  CostaRica = "cr",
  Croatia = "hr",
  Cyprus = "cy",
  CzechRepublic = "cz",
  Denmark = "dk",
  DominicanRepublic = "do",
  Ecuador = "ec",
  Egypt = "eg",
  ElSalvador = "sv",
  Estonia = "ee",
  Finland = "fi",
  France = "fr",
  Germany = "de",
  Ghana = "gh",
  Greece = "gr",
  Guatemala = "gt",
  Honduras = "hn",
  HongKong = "hk",
  Hungary = "hu",
  Iceland = "is",
  India = "in",
  Indonesia = "id",
  Iraq = "iq",
  Ireland = "ie",
  Israel = "il",
  Italy = "it",
  Japan = "jp",
  Jordan = "jo",
  Kazakhstan = "kz",
  Kenya = "ke",
  Kuwait = "kw",
  Kyrgyzstan = "kg",
  Latvia = "lv",
  Lebanon = "lb",
  Luxembourg = "lu",
  Macau = "mo",
  Malaysia = "my",
  Mexico = "mx",
  Mongolia = "mn",
  Morocco = "ma",
  Nepal = "np",
  Netherlands = "nl",
  NewZealand = "nz",
  Norway = "no",
  Oman = "om",
  Panama = "pa",
  Paraguay = "py",
  Peru = "pe",
  Philippines = "ph",
  Poland = "pl",
  Portugal = "pt",
  Qatar = "qa",
  Romania = "ro",
  Russia = "ru",
  SaudiArabia = "sa",
  Singapore = "sg",
  Slovakia = "sk",
  Slovenia = "si",
  SouthAfrica = "za",
  SouthKorea = "kr",
  Spain = "es",
  SriLanka = "lk",
  Sweden = "se",
  Switzerland = "ch",
  Taiwan = "tw",
  Thailand = "th",
  Turkey = "tr",
  Ukraine = "ua",
  UnitedArabEmirates = "ae",
  UnitedKingdom = "gb",
  UnitedStates = "us",
  Uzbekistan = "uz",
  Vietnam = "vn"
}