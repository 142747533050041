import { LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';

@customElement('pricing-component')
export class PricingComponent extends LitElement {
  @property({ type: Number }) price: number = 5;
  @property({ type: String }) buttonTitle: string = 'Get Started';

  createRenderRoot() {
    return this;
  }

  render() {
    return html`
      <div class="flex flex-col mx-auto space-y-4 max-w-lg p-6 rounded-3xl border border-gray-200">
          <h3 class="text-5xl font-bold">Explore</h3>
          
          <p class="font-light text-gray-500 sm:text-lg">Discover popular keywords on the AppStore.</p>
          
          <div class="flex items-baseline">
            <span class="mr-2 text-6xl font-extrabold">$${this.price}</span>
            <span class="text-gray-500">/month</span>
          </div>

          <!-- List -->

          <ul role="list" class="mb-8 space-y-4 text-left">
              <li class="flex items-center space-x-3">
                  <!-- Icon -->
                  <svg class="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                  <span>Keyword <span class="font-semibold">popularity</span> and <span class="font-semibold">difficulty</span></span>
              </li>
              <li class="flex items-center space-x-3">
                  <!-- Icon -->
                  <svg class="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                  <span>Keyword <span class="font-semibold">suggestions</span></span>
              </li>
              <li class="flex items-center space-x-3">
                  <!-- Icon -->
                  <svg class="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                  <span>AppStore <span class="font-semibold">rankings</span></span>
              </li>
              <li class="flex items-center space-x-3">
                  <!-- Icon -->
                  <svg class="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                  <span>Title & subtitle <span class="font-semibold">opportunities</span></span>
              </li>
              <li class="flex items-center space-x-3">
                  <!-- Icon -->
                  <svg class="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                  <span>Search <span class="font-semibold">90 countries</span></span>
              </li>
          </ul>
          
          <button
            class="btn btn-primary w-full"
            @click=${() => this.dispatchEvent(new CustomEvent('pricing-button-click'))}
          >
            ${this.buttonTitle}
          </button>
          
        </div>
    `;
  }
}
