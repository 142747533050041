import { html } from "lit";
import { customElement, state } from "lit/decorators.js";
import { BaseElement } from "../app.js";
import { Api } from "../api.js";
import { Country } from "../model/country.js";
import { Store } from "../utils/store.js";

@customElement("main-page")
export class MainPage extends BaseElement {
    @state() term = ""; // To hold the search term
    @state() country: Country = Country.USA; // Default country
    @state() popularity: number | null = null;
    @state() loading = false; // To track loading state

    // Fetch keyword popularity
    async fetchKeyword() {
        if (!this.term.trim()) {
            alert("Please enter a search term.");
            return;
        }
        this.loading = true; // Set loading state to true
        try {
            const user = Store.getUser();
            if (!user.aaplUser) {
                alert("Missing SearchAds user. Enter in settings.");
                return;
            }
            if (!user.aaplPass) {
                alert("Missing SearchAds password. Enter in settings.");
                return;
            }
            const keyword = await Api.keyword(
                user.aaplUser,
                user.aaplPass,
                this.term, this.country,
            );
            if (keyword instanceof Error) {
                console.error("Error fetching keyword popularity:", keyword.message);
                return;
            }
            this.popularity = keyword.popularity;
        } catch (error) {
            console.error("Error fetching keyword popularity:", error);
            alert("Failed to fetch keyword popularity. Please try again.");
        } finally {
            this.loading = false; // Reset loading state
        }
    }

    render() { return html`
    <div class="container max-w-3xl p-4 md:p-6 lg:p8">
        <div class="flex flex-row items-center space-x-2">
            <h1 class="w-full">AppShovel</h1>
            <a class="btn btn-s" href="/settings">Settings</a>
        </div>
        </br>
        <p class="text-xl mb-3 text-green">Find popular keywords on the Apple AppStore and get more organic downloads.</p>
        <div class="divider"></div>
        </br>
        <h2>Keyword Search</h2>
        <div class="flex flex-col md:flex-row space-y-2 md:space-y-0 space-x-0 md:space-x-2">
            <input class="input input-bordered w-full"
                type="text"
                placeholder="Enter keyword"
                .value=${this.term}
                @input=${(e: InputEvent) => (this.term = (e.target as HTMLInputElement).value)}
                ?disabled=${this.loading}/>
            <select class="select select-bordered w-full md:max-w-32"
                .value=${this.country}
                @change=${(e: Event) => (this.country = (e.target as HTMLSelectElement).value as Country)}
                ?disabled=${this.loading}>
                ${Object.values(Country).map(
                    (code) =>
                        html`<option value=${code} ?selected=${this.country === code}>
                            ${Object.keys(Country).find(
                                (key) => Country[key as keyof typeof Country] === code
                            )}
                        </option>`
                )}
            </select>
            <button class="btn btn-primary w-full md:max-w-32"
                @click=${this.fetchKeyword}
                ?disabled=${this.loading}>${this.loading ? html`Loading...` : html`Search`}</button>
        </div>
        </br>
        <div class="flex justify-center">
            ${this.popularity === null ? html`Enter a keyword above to search.` : html`
                <div class="stats shadow">
                <div class="stat">
                    <div class="stat-title">Popularity</div>
                    <div class="stat-value text-primary">${this.popularity}</div>
                </div>
                </div>
            `}
        </div>
        </br>
        <div class="divider"></div>
        <h2>FAQ</h2>
        <h3>What is App Store Optimization?</h3>
        <p>App Store Optimization (ASO) is the process of improving an app’s visibility in app stores like the Apple App Store or Google Play Store. It involves optimizing elements like the app title, description, keywords, visuals, and user ratings to drive more organic downloads.</p>
        <p>Keywords are essential in App Store Optimization because they determine how easily users can find your app in search results. By strategically selecting and optimizing keywords, you increase your app’s visibility for relevant searches, leading to more organic downloads and better ranking within the app store.</p>
        </br>
        <h3>How does AppShovel get keyword popularity?</h3>
        <p>AppShovel fetches keyword popularity data directly from Apple Search Ads, which is Apple’s advertising platform for promoting apps in the App Store. Apple Search Ads provides a keyword popularity score that reflects how often a specific keyword is searched by users in the App Store.</p>
        </br>
        <h3>How can I use AppShovel?</h3>
        <p>In order to search for keyword popularity, you need to enter your Apple ID and and <a target=_blank href="https://support.apple.com/en-us/102654">app-specific password</a>. You can alos create a new Apple ID if you don't want to use your main account.<p>
        <p>You also need to setup an <a href="">Apple SearchAds Account</a> and connect at least one campaign group to your App Store Connect account.</p>
        </br>
    </div>
    `;
    }
}
