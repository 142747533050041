import { html } from "lit";
import { customElement, state } from "lit/decorators.js";
import { BaseElement, isProduction } from "../app.js";

import '../components/header-component.ts';
import '../components/footer-component.ts';

import { Api } from "../api.js";
import { User } from "../model/user.js";

// Declare the Paddle interface globally
declare const Paddle: any;

@customElement("pricing-page")
export class PricingPage extends BaseElement {
  @state() user?: User;
  @state() customerPortalUrl?: string;
  @state() price: number = 5; // Set default price

  constructor() {
    super();
    const isProd = isProduction();
    const script = document.createElement("script");
    script.src = isProd ? "https://cdn.paddle.com/paddle/v2/paddle.js" : "https://sandbox-cdn.paddle.com/paddle/v2/paddle.js";
    script.onload = () => {
      if (!isProd) {
        Paddle.Environment.set("sandbox");
      }
      Paddle.Initialize({ 
        token: isProd ? "live_52a59e159631aed4efaa9b144ec" : "test_bcd53cbb087424af9857f7c0ddf"
      });
    };
    document.head.appendChild(script);
  }

  connectedCallback(): void {
    super.connectedCallback();
    this.fetchUser();
  }

  async fetchUser(): Promise<void> {
    try {
      const result = await Api.user();
        if (result instanceof Error) {
            throw result;
        }
        this.user = result;

        const customerPortalUrlResponse = await Api.customerPortalUrl();
        if (!(customerPortalUrlResponse instanceof Error)) {
            this.customerPortalUrl = customerPortalUrlResponse.url;
        }
    } catch (error) {
      console.error("Error fetching user data:", error);
      window.location.href = "/login";
    }
  }

  firstUpdated() {
    const pricingComponent = this.querySelector('pricing-component');
    if (pricingComponent) {
        pricingComponent.addEventListener('pricing-button-click', () => this.manageOrCheckout());
    }
  }

  manageOrCheckout(): void {
    if (this.isSubscribed()) {
      window.open(this.customerPortalUrl, '_blank');
    } else {
      this.checkout();
    }
  }

  isSubscribed(): boolean {
    return this.user?.subscription?.status === 'active';
  }

  // Simulate subscription action
  checkout(): void {
    const user = this.user;
    if (!user) {
      return;
    }
    const isProd = isProduction();
    const priceId = isProd ? "pri_01jmhsp2gfrwfyk54rfzt0rxw6" : "pri_01jhqttp75jdjvaaw0zrvyfek5";
    const successUrl = `${window.location.protocol}//${window.location.host}/search`;
    Paddle.Checkout.open({
      settings: {
        successUrl: successUrl
      },
      items: [
        {
          priceId: priceId
        },
      ],
      customer: {
        email: user.email
      },
      customData: {
        user_id: user.id,
      }
    });
  }

  render() {
    return html`
      <div class="container max-w-5xl p-4 md:p-6 lg:p-8 my-6">

        <header-component></header-component>

        <pricing-component 
          price="${this.price}"
          buttonTitle="${this.isSubscribed() ? `Manage Subscription` : `Get Started`}"></pricing-component>
        <footer-component></footer-component>
      </div>
    `;
  }
}
