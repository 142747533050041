import { html } from "lit";
import { customElement, state } from "lit/decorators.js";
import { BaseElement } from "../app.js";
import { Store, Settings } from "../utils/store.js";
import { Api } from "../api.js";
import { User } from "../model/user.js";

@customElement("settings-page")
export class SettingsPage extends BaseElement {
    @state()
    private settings: Settings = Store.getSettings();
    @state()
    private user: User | null = null;
    @state()
    private customerPortalUrl: string | null = null;
    @state()
    private loading = false;
    @state()
    private verfyingSearchAdsCredentials = false;

    async connectedCallback() {
        super.connectedCallback();
        this.loading = true;
        const userResponse = await Api.user();
        if (userResponse instanceof Error) {
            window.location.href = "/login";
            return;
        }
        this.user = userResponse;
        
        const customerPortalUrlResponse = await Api.customerPortalUrl();
        if (!(customerPortalUrlResponse instanceof Error)) {
            this.customerPortalUrl = customerPortalUrlResponse.url;
        }
        this.loading = false;
    }
    
    private async verifyAndSaveSaerchAdsCredentials() {
        this.verfyingSearchAdsCredentials = true;
        const verified = await Api.verifySearchAdsCredentials(this.settings.aaplUser, this.settings.aaplPass);
        if (verified instanceof Error) {
            alert("Could not verify Search Ads credentials. Please check your credentials and try again.");
        } else {
            Store.setSettings(this.settings);
            alert("Saved Search Ads credentials! You can now search for keywords.");
        }
        this.verfyingSearchAdsCredentials = false;
    }

    private async logout() {
        await Api.logout();
        window.location.href = "/";
    }

    render() {
        return html`
        <div class="container max-w-5xl p-4 md:p-6 lg:p8">

            <header-component></header-component>

            <h1>Settings</h1>
            </br>
            <h2>Account</h2>
            <div class="flex flex-col space-y-2">
                <p>${this.user?.email}</p>
                <p>Subscription: ${this.user?.subscription?.status?.charAt(0).toUpperCase() + this.user?.subscription?.status?.slice(1)}</p>
                <a class="btn w-full max-w-xs" ?disabled=${this.loading} href="${this.customerPortalUrl}" target="_blank">Manage Subscription</a>
                <button class="btn btn-error w-full max-w-xs" @click=${this.logout}>Logout</button>
            </div>
            </br>
            <h2>AppStore Connect Account</h2>
            <form class="flex flex-col space-y-6"
                @submit=${(e: Event) => {
                    e.preventDefault();
                    this.verifyAndSaveSaerchAdsCredentials();
                }}
            >
                <div class="flex flex-col space-y-2">
                    <p>Apple ID</p>
                    <input class="input input-bordered w-full max-w-xs" type="text"
                        .value=${this.settings.aaplUser}
                        @input=${(e: Event) =>
                            (this.settings = { ...this.settings, aaplUser: (e.target as HTMLInputElement).value })}
                    />
                </div>
                
                <div class="flex flex-col space-y-2">
                    <p>App Specific Password</p>
                    <input class="input input-bordered w-full max-w-xs" type="password"
                        .value=${this.settings.aaplPass}
                        @input=${(e: Event) =>
                            (this.settings = { ...this.settings, aaplPass: (e.target as HTMLInputElement).value })}
                    />
                </div>
                <button class="btn btn-primary w-full max-w-xs" ?disabled=${this.verfyingSearchAdsCredentials} type="submit">${ this.verfyingSearchAdsCredentials ? "Verifying..." : "Save" }</button>
            </form>
            <div class="divider"></div>
            <p>In order to search for keyword popularity, you need to enter your Apple ID and and <a target=_blank href="https://support.apple.com/en-us/102654" class="font-medium text-blue-500">app-specific password</a>. You can also create a new Apple ID if you don't want to use your main account.<p>
            <p>You also need to setup an <a target=_blank href="https://searchads.apple.com" class="font-medium text-blue-500">Apple SearchAds Account</a> and connect at least one campaign group to your App Store Connect account.</p>
        <div>
        `;
    }
}
