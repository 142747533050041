import { html } from "lit";
import { customElement, state } from "lit/decorators.js";
import { BaseElement, isAuthenticated } from "../app.js";

import '../components/header-component.ts';
import '../components/footer-component.ts';
import '../components/pricing-component.ts';

@customElement("main-page")
export class MainPage extends BaseElement {
    @state() mail = "";
    @state() isMailValid = false;
    @state() loading = false;

    firstUpdated() {
        const pricingComponent = this.querySelector('pricing-component');
        if (pricingComponent) {
            pricingComponent.addEventListener('pricing-button-click', () => this.handlePricingButtonClick());
        }
    }

    handlePricingButtonClick() {
        if (isAuthenticated()) {
            window.location.href = "/pricing";
        } else {
            window.location.href = "/login";
        }
    }

    validateEmail(email: string): boolean {
        // Simple regex for email validation
        const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        return emailRegex.test(email);
    }

    handleInput(e: InputEvent) {
        this.mail = (e.target as HTMLInputElement).value;
        this.isMailValid = this.validateEmail(this.mail);
    }

    render() { return html`
    <div class="flex flex-col h-screen container max-w-5xl p-4 md:p-6 lg:p8">
        
        <header-component></header-component>
        
        <p class="text-xl mb-3 text-green pb-4">Find keywords that rank on the Apple AppStore and get more organic downloads.</p>
        
        <img src="hero.webp" alt="AppShovel Keyword Search"/>
        
        </br>

        <div class="divider"></div>

        <h2>Pricing</h2>

        <pricing-component price="5" buttonTitle="Get Started"></pricing-component>

        </br>

        <div class="divider"></div>

        <h2>FAQ</h2>
        <h3>What is App Store Optimization?</h3>
        <p>App Store Optimization (ASO) is the process of improving an app's visibility in app stores like the Apple App Store or Google Play Store. It involves optimizing elements like the app title, description, keywords, visuals, and user ratings to drive more organic downloads.</p>
        <p>Keywords are essential in App Store Optimization because they determine how easily users can find your app in search results. By strategically selecting and optimizing keywords, you increase your app's visibility for relevant searches, leading to more organic downloads and better ranking within the app store.</p>
        </br>
        <h3>How does AppShovel get keyword popularity?</h3>
        <p>AppShovel fetches keyword popularity data directly from Apple Search Ads, which is Apple's advertising platform for promoting apps in the App Store. Apple Search Ads provides a keyword popularity score that reflects how often a specific keyword is searched by users in the App Store.</p>
        </br>
        <h3>How can I use AppShovel?</h3>
        <p>In order to search for keyword popularity, you need to enter your Apple ID and and <a target=_blank href="https://support.apple.com/en-us/102654">app-specific password</a>. You can also create a new Apple ID if you don't want to use your main account.<p>
        <p>You also need to setup an <a href="">Apple SearchAds Account</a> and connect at least one campaign group to your App Store Connect account.</p>
        </br>

        <footer-component></footer-component>

    </div>
    `;
    }
}
