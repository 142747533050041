import { html } from "lit";
import { customElement, state } from "lit/decorators.js";
import { BaseElement } from "../app.js";
import { Api } from "../api.js";
import { Country } from "../model/country.js";
import { Platform } from "../model/platform.js";
import { Store } from "../utils/store.js";

import { Keyword, KeywordRecommendation } from "../model/keyword.js";
import { Rank } from "../model/rank.js";

import '../components/header-component.ts';
import '../components/rating-component.ts';

@customElement("search-page")
export class SearchPage extends BaseElement {
    @state() term = "";
    @state() country: Country = Country.UnitedStates;
    @state() platform: Platform = Platform.iPhone;

    @state() keyword: Keyword | undefined;
    @state() history: Keyword[] = [];
    @state() canLoadAllApps = false;

    @state() loading = false; // To track loading state

    async connectedCallback() {
        super.connectedCallback();
        try {
            const history = await Api.history();
            if (history instanceof Error) {
                console.error("Error fetching history", history.message);
                return;
            }
            this.history = history.keywords;
        } catch (error) {
            console.error("Error fetching initial history:", error);
        }
    }

    // Fetch keyword popularity
    async fetchKeyword(allApps: boolean) {
        if (!this.term.trim()) {
            alert("Please enter a search term.");
            return;
        }
        this.loading = true; // Set loading state to true
        try {
            const settings = Store.getSettings();
            if (!settings.aaplUser) {
                alert("Missing SearchAds user. Please enter it in settings.");
                return;
            }
            if (!settings.aaplPass) {
                alert("Missing SearchAds password. Please enter it in settings.");
                return;
            }
            const keyword = await Api.keyword(
                settings.aaplUser,
                settings.aaplPass,
                this.term,
                this.country,
                this.platform,
                allApps,
            );
            if (keyword instanceof Error) {
                console.error("Error fetching keyword", keyword.message);
                return;
            }
            this.keyword = keyword;
            this.canLoadAllApps = !allApps;

            const history = await Api.history();
            if (history instanceof Error) {
                console.error("Error fetching history", history.message);
                return;
            }
            this.history = history.keywords;
        } catch (error) {
            console.error("Error fetching keyword:", error);
            alert("Failed to fetch keyword. Please try again.");
        } finally {
            this.loading = false; // Reset loading state
        }
    }

    highlightTerm(text: string, term: string | undefined): unknown {
        if (term == null) return text;
        if (!term.trim()) return text; // If no term is provided, return the original text
    
        const escapedTerm = term.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escape special regex characters
        const regex = new RegExp(`(${escapedTerm})`, "gi"); // Create a case-insensitive regex
        const parts = text.split(regex);
    
        return parts.map((part, index) =>
            regex.test(part)
                ? html`<span class="bg-yellow-100">${part}</span>`
                : part
        );
    }

    shortenNumber(value: number): string {
        if (value >= 1_000_000) {
            return (value / 1_000_000).toFixed(1).replace(/\.0$/, '') + 'M';
        }
        if (value >= 1_000) {
            return (value / 1_000).toFixed(1).replace(/\.0$/, '') + 'k';
        }
        return value.toString();
    }

    getCountryFlag(country: Country): string {
        return this.countryCodeToFlagEmoji(country.toString());
    }

    letterToLetterEmoji(letter: string): string {
        return String.fromCodePoint(letter.toLowerCase().charCodeAt(0) + 127365);
    }

    countryCodeToCountry(countryCode: string): string {
        const country = countryCode.split('-').pop() as string;
        return country.toUpperCase();
    }
    
    countryCodeToFlagEmoji(countryCode: string): string {
        if (!countryCode) {
            throw new Error('countryCode is required');
        }
        return Array.from(this.countryCodeToCountry(countryCode))
            .map(letter => this.letterToLetterEmoji(letter))
            .join('');
    }

    async searchTerm(term: string) {
        this.term = term;
        await this.fetchKeyword(false);
    }

    render() { return html`
    <div class="container max-w-5xl p-4 md:p-6 lg:p8 space-y-4">
    
        <header-component></header-component>
        
        <div class="flex flex-row items-center space-x-2">
            <h1 class="w-full">Keyword Search</h1>

            ${this.history?.length > 0 ? html`
             <button class="btn btn-s"
                @click=${async () => {
                    const terms = this.history?.map(k => k.term).join(',');
                    await navigator.clipboard.writeText(terms);
                    alert('Keywords copied to clipboard!');
                }}
                ?disabled=${this.loading}>
                Copy
            </button>
            <button class="btn btn-s"
                @click=${async () => {
                    if (confirm('Are you sure you want to clear all history?')) {
                        const result = await Api.historyDeleteAll();
                        if (!(result instanceof Error)) {
                            this.history = [];
                        }
                    }
                }}
                ?disabled=${this.loading}>
                Clear
            </button>
            ` : ''}
        </div>

        <div class="flex flex-wrap gap-2">
            ${this.history?.filter(keyword => 
                keyword.country.toString() === this.country.toString() && 
                keyword.platform.toString() === this.platform.toString()
            ).map(keyword => html`
                <div class="relative group">
                    <button class="btn btn-sm" 
                        @click=${async () => {
                            this.term = keyword.term;
                            this.country = keyword.country;
                            this.platform = keyword.platform;
                            await this.fetchKeyword(false);
                        }}
                        ?disabled=${this.loading}>
                        ${keyword.term}  <span class="text-gray-500">${keyword.popularity} | ${keyword.difficulty}</span>
                    </button>
                    <button 
                        class="absolute -top-2 -right-2 bg-red-500 text-white rounded-full w-5 h-5 flex items-center justify-center 
                               opacity-0 group-hover:opacity-100 transition-opacity leading-none pb-0.5"
                        @click=${async (e: Event) => {
                            e.stopPropagation();
                            const result = await Api.historyDelete(keyword.id);
                            if (!(result instanceof Error)) {
                                const historyResult = await Api.history();
                                if (!(historyResult instanceof Error)) {
                                    this.history = historyResult.keywords;
                                }
                            }
                        }}>
                        ×
                    </button>
                </div>
            `)}
        </div>

        <div class="flex flex-col md:flex-row space-y-2 md:space-y-0 space-x-0 md:space-x-2">
            
            <input class="input input-bordered w-full"
                type="text"
                placeholder="Enter keyword"
                .value=${this.term}
                @input=${(e: InputEvent) => (this.term = (e.target as HTMLInputElement).value)}
                @keydown=${(e: KeyboardEvent) => e.key === 'Enter' && this.fetchKeyword(false)}
                ?disabled=${this.loading}/>
            <select class="select select-bordered w-full md:max-w-fit"
                .value=${this.country}
                @change=${(e: Event) => (this.country = (e.target as HTMLSelectElement).value as Country)}
                ?disabled=${this.loading}>
                ${Object.values(Country).map(
                    (code) => 
                        html`<option value=${code} ?selected=${this.country === code}>
                            ${this.getCountryFlag(code)} ${Object.keys(Country).find((key) => Country[key as keyof typeof Country] === code)?.replace(/([A-Z])/g, ' $1').trim()}
                        </option>`
                )}
            </select>
            <select
                class="select select-bordered w-full md:max-w-fit"
                .value=${this.platform}
                @change=${(e: Event) => (this.platform = (e.target as HTMLSelectElement).value as Platform)}
                ?disabled=${this.loading}>
                ${Object.values(Platform).map(
                    (platform) =>
                        html`<option value=${platform} ?selected=${this.platform === platform}>
                            ${Object.keys(Platform).find(
                                (key) => Platform[key as keyof typeof Platform] === platform
                            )}
                        </option>`
                )}
            </select>
        </div>
        
        ${this.keyword ? html`
            <div class="space-y-8">
                <h2>${this.keyword.term}</h2>
                <div class="flex flex-row items-center space-x-8">
                    <div>
                    <p class="text-lg text-gray-500">Popularity</p>
                    <p class="text-5xl text-primary font-bold">${this.keyword.popularity}</p>
                    </div>
                    <div>
                    <p class="text-lg text-gray-500">Difficulty</p>
                    <p class="text-5xl text-primary font-bold">${this.keyword.difficulty}</p>
                    </div>
                    <div>
                    <p class="text-lg text-gray-500">Results</p>
                    <p class="text-5xl text-primary font-bold">${this.keyword.ranks.length}</p>
                    </div>
                </div>
                ${this.keyword && this.keyword.recommendations.length > 0 ? html`
                <h3>Recommendations</h3>
                <div class="flex flex-wrap gap-2">
                    ${(() => {
                        // Find the maximum popularity in the dataset
                        const maxPopularity = Math.max(...this.keyword.recommendations.map((k) => k.popularity));
                        const minPopularity = Math.min(...this.keyword.recommendations.map((k) => k.popularity));

                        return this.keyword.recommendations.map((recommendedKeyword: KeywordRecommendation) => {
                            // Dynamically scale based on the min and max popularity
                            const scaledPopularity =
                                (recommendedKeyword.popularity - minPopularity) / (maxPopularity - minPopularity || 1); // Handle divide by zero
                            const intensity = Math.round(scaledPopularity * 600) + 100;

                            return html`
                                <div
                                    class="px-3 py-1 rounded-full text-sm inline-flex items-center bg-primary text-primary-content cursor-pointer hover:brightness-90"
                                    style="--tw-bg-opacity: ${(intensity / 700).toFixed(2)}; --tw-text-opacity: 1;"
                                    @click=${() => this.searchTerm(recommendedKeyword.name)}>
                                    <span class="font-bold">${recommendedKeyword.name}</span>
                                </div>
                            `;
                        });
                    })()}
                </div>
                ` : html``}

                <h3>Ranks</h3>
                <div class="flex flex-col">
                ${this.keyword.ranks
                    .filter((rank: Rank) => rank.app !== undefined) // Filter out ranks without an app
                    .map(
                        (rank: Rank, index: number) => {
                            const app = rank.app!; // Safe to assert because of the filter
                            return html`
                                <div class="flex flex-row items-center space-x-4 ${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'} py-2 px-4 rounded-lg">
                                    <img src=${app.icon} alt="${app.title} App Icon" class="w-14 h-14 rounded-2xl border border-gray-100" />
                                    <div class="flex-grow">
                                        <div class="badge">${index + 1}</div>
                                        <p class="text-base font-semibold">${this.highlightTerm(app.title, this.keyword?.term)}</p>
                                        <p class="text-sm text-gray-500">${this.highlightTerm(app.subtitle || "--", this.keyword?.term)}</p>
                                        <div class="flex flex-row items-center space-x-2">
                                            <rating-component value="${app.ratingValue}" max="5" step="0.1"></rating-component>
                                            <p class="text-sm text-gray-500">${this.shortenNumber(app.ratingCount)}</p>
                                        </div>
                                    </div>
                                    <a href="https://apps.apple.com/app/id${app.id}" target="_blank" rel="noopener noreferrer" class="btn btn-sm btn-circle">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                            <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                                            <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
                                        </svg>
                                    </a>
                                </div>
                            `;
                        }
                    )}
                ${this.canLoadAllApps ? html`
                    <button class="btn w-full md:max-w-32 mx-auto py-2 px-4"
                        @click=${() => this.fetchKeyword(true)}
                        ?disabled=${this.loading}>
                        ${this.loading ? html`Loading...` : html`Load More`}
                    </button>
                ` : ''}
                </div>
            </div>
        ` : html``}
    </div>
    `;
    }
}
