import { error } from "./utils/utils.js";
import { Keyword } from "./model/keyword.js";
import { Country } from "./model/country.js";
import { encryptData } from "./utils/utils.js";
import { Platform } from "./model/platform.js";
import { User } from "./model/user.js";

export interface JsonValue {
    [key: string]: any;
}

function apiBaseUrl() {
    if (typeof location === "undefined") return "http://localhost:3333/api/";
    return location.href.includes("localhost") || location.href.includes("192.168.1") ? `http://${location.hostname}:3333/api/` : "/api/";
}

export async function apiGet<T>(endpoint: string, headers: HeadersInit = {}): Promise<T | Error> {
    try {
        const result = await fetch(apiBaseUrl() + endpoint, {credentials: 'include', headers: headers});
        handleResult(result);
        return (await result.json()) as T;
    } catch (e) {
        return error(`Request GET /api/${endpoint} failed`, e);
    }
}

export async function apiGetText(endpoint: string): Promise<string | Error> {
    try {
        const result = await fetch(apiBaseUrl() + endpoint);
        handleResult(result);
        return await result.text();
    } catch (e) {
        return error(`Request GET /api/${endpoint} failed`, e);
    }
}

export async function apiGetBlob(endpoint: string): Promise<Blob | Error> {
    try {
        const result = await fetch(apiBaseUrl() + endpoint);
        handleResult(result);
        return await result.blob();
    } catch (e) {
        return error(`Request GET /api/${endpoint} failed`, e);
    }
}

export async function apiGetArrayBuffer(endpoint: string): Promise<ArrayBuffer | Error> {
    try {
        const result = await fetch(apiBaseUrl() + endpoint);
        handleResult(result);
        return await result.arrayBuffer();
    } catch (e) {
        return error(`Request GET /api/${endpoint} failed`, e);
    }
}

export async function apiPost<T>(
    endpoint: string,
    params: URLSearchParams | FormData | JsonValue | Blob | ArrayBuffer,
    contentType?: string
) {
    let headers: HeadersInit = {};
    let body: string | FormData | Blob | ArrayBuffer;

    if (params instanceof URLSearchParams) {
        headers = { "Content-Type": "application/x-www-form-urlencoded" };
        body = params.toString();
    } else if (params instanceof FormData) {
        body = params;
    } else if (params instanceof Blob || params instanceof ArrayBuffer) {
        // Handle binary data
        if (contentType) {
            headers = { "Content-Type": contentType };
        } else if (params instanceof Blob) {
            headers = { "Content-Type": params.type || "application/octet-stream" };
        } else {
            headers = { "Content-Type": "application/octet-stream" };
        }
        body = params;
    } else {
        // Handle JSON data
        headers = { "Content-Type": "application/json" };
        body = JSON.stringify(params);
    }

    try {
        const result = await fetch(apiBaseUrl() + endpoint, {
            method: "POST",
            headers: headers,
            body: body,
            credentials: 'include',
        });
        handleResult(result);
        return (await result.json()) as T;
    } catch (e) {
        return error(`Request POST /api/${endpoint} failed`, e);
    }
}

export async function apiDelete(endpoint: string): Promise<void | Error> {
    try {
        const result = await fetch(apiBaseUrl() + endpoint, {
            method: "DELETE",
            credentials: 'include',
        });
        handleResult(result);
        return await result.json();
    } catch (e) {
        return error(`Request DELETE /api/${endpoint} failed`, e);
    }
}

function handleResult(result: Response) {
    if (result.ok) return;
    if (result.status === 401) {
        document.cookie = "authTokenExpiration=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT";
        window.location.href = "/";
    }
    if (result.status === 402) {
        window.location.href = "/pricing";
    }
    throw new Error();
}

export class Api {

    static async user(): Promise<User | Error> {
        return await apiGet<User>( "user");
    }

    static async verifySearchAdsCredentials(user: string, pass: string): Promise<void | Error> {
        return await apiGet<void>("verifySearchAdscredentials", {
            "x-aapl-user": encryptData(user),
            "x-aapl-pass": encryptData(pass),
        });
    }

    static async customerPortalUrl(): Promise<{ url: string } | Error> {
        return await apiGet<{ url: string }>( "customer-portal-url");
    }

    static async logout(): Promise<void | Error> {
        return await apiPost<void>( "logout", {});
    }

    static async keyword(
        user: string,
        pass: string,
        term: string,
        country: Country,
        platform: Platform,
        allApps: boolean,
    ): Promise<Keyword | Error> {
        const encodedTerm = encodeURIComponent(term.trim());
        const endpoint = `keyword?term=${encodedTerm}&country=${country}&platform=${platform}&allApps=${allApps}`;
        return await apiGet<Keyword>(endpoint, {
            "x-aapl-user": encryptData(user),
            "x-aapl-pass": encryptData(pass),
        });
    }

    static async history(): Promise<{ keywords: Keyword[] } | Error> {
        return await apiGet<{ keywords: Keyword[] }>(`history`);
    }

    static async historyDelete(keywordId: string): Promise<void | Error> {
        const encodedKeywordId = encodeURIComponent(keywordId.trim().toLocaleLowerCase());
        return await apiDelete(`history?keywordId=${encodedKeywordId}`);
    }

    static async historyDeleteAll(): Promise<void | Error> {
        return await apiDelete(`history`);
    }

    static async newsletterSubscribe(email: string): Promise<{ success: boolean, message: string } | Error> {
        return await apiPost<{ success: boolean, message: string }>(
            "newsletter-subscribe",
            { email: email }
        );
    }

    static async magicLink(email: string): Promise<{ message: string } | Error> {
        return await apiPost<{ message: string }>(
            "magic-link",
            { email: email }
        );
    }
}
