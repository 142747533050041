import { LitElement, html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { isAuthenticated } from '../app.js';

@customElement('header-component')
export class HeaderComponent extends LitElement {

  createRenderRoot() {
    return this;
  }

  goToLogin() {
    window.location.href = "/login";
  }

  goToSearch() {
    window.location.href = "/search";
  }

  goToSettings() {
    window.location.href = "/settings";
  }

  render() {
    return html`
      <div class="flex flex-row items-center space-x-3">
        <img src="logo.svg" alt="AppShovel Logo" class="w-12 h-12"/>
        <a href="/" class="text-3xl font-bold relative w-full">AppShovel</a>

        ${!isAuthenticated() && window.location.pathname === '/' ? html`
          <button class="btn btn-primary" @click=${this.goToLogin} >Get Started</button>
        ` : null}

        ${isAuthenticated() && window.location.pathname === '/' ? html`
          <button class="btn" @click=${this.goToSearch} >Search</button>
        ` : null}

        ${isAuthenticated() && window.location.pathname === '/search' ? html`
          <button class="btn" @click=${this.goToSettings} >Settings</button>
        ` : null}

        ${isAuthenticated() && window.location.pathname === '/pricing' ? html`
          <button class="btn" @click=${this.goToSettings} >Settings</button>
        ` : null}
      </div>
      <div class="divider"></div>
    `;
  }
}
