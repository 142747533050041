import { html } from "lit";
import { customElement } from "lit/decorators.js";
import { BaseElement } from "../app.js";

import '../components/header-component.ts';
import '../components/footer-component.ts';

@customElement("imprint-page")
export class ImprintPage extends BaseElement {
    render() {
        return html`
        <div class="flex flex-col h-screen container max-w-5xl p-4 md:p-6 lg:p8">

            <header-component></header-component>

            <h1>Imprint</h1>
            </br>
            <h2>Owner & Data Protection Officer</h2>
            <p>Dipl.-Ing. Denis Andrašec</p>
            <p>Glacisstrasse 63/1</p>
            <p>8010 Graz</p>
            <p>Austria</p>
            <p><a class="text-blue-500" href="mailto:denis@andrasec.com">denis@andrasec.com</a></p>
            </br>
            <h2>Supervisory Authority</h2>
            <p>You have the right to lodge a complaint with the following supervisory authority:</p>
            <p>Österreichische Datenschutzbehörde Wickenburggasse 8-10 1080 Wien Österreich <a class="font-medium text-blue-500" href="mailto:dsb@dsb.gv.at">dsb@dsb.gv.at</a></p>
            
            <div class="flex-grow"></div>
            <footer-component></footer-component>
        <div>
        `;
    }
}
