import { LitElement, html } from 'lit';
import { customElement } from 'lit/decorators.js';

@customElement('footer-component')
export class FooterComponent extends LitElement {

  createRenderRoot() {
    return this;
  }

  render() {
    return html`
      
      <div class="divider"></div>
      <footer class="flex flex-row space-x-4 pb-6">
        <a href="/imprint">Imprint</a>
        <a href="/terms">Terms</a>
        <a href="/privacy">Privacy</a>
      </footer>
    `;
  }
}
